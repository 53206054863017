.App {
  text-align: center;
  background-color: #000;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  position: fixed;
  height: 10vh;
  width: 100%;
  bottom: 0;
  left: 0;
}

.footer-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  max-width: 500px;
  width: 70%;
  margin: auto;

  >a {
    color: white;
  }
}